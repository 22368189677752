@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/AvenirNext-Regular.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/AvenirNext-Demi.woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/AvenirNext-Demi.woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/AvenirNext-Bold.ttf");
  font-weight: bold;
}

p {
  white-space: pre-line;
}

@layer components {
  .text-r-7xl {
    @apply text-5xl;
    @apply md:text-6xl;
    @apply lg:text-7xl;
  }
  .text-r-6xl {
    @apply text-4xl;
    @apply sm:text-5xl;
    @apply md:text-6xl;
  }
  .text-r-5xl {
    @apply text-3xl;
    @apply md:text-5xl;
  }
  .text-r-4xl {
    @apply text-2xl;
    @apply xs:text-3xl;
    @apply md:text-4xl;
  }
  .text-r-3xl {
    @apply text-xl;
    @apply xs:text-2xl;
    @apply md:text-3xl;
  }
  .text-r-2xl {
    @apply text-lg;
    @apply xs:text-xl;
    @apply md:text-2xl;
  }
  .text-r-xl {
    @apply text-base;
    @apply md:text-lg;
    @apply lg:text-xl;
  }
  .text-r-lg {
    @apply text-base;
    @apply md:text-lg;
  }
  .text-r-base {
    @apply text-sm;
    @apply lg:text-base;
  }
  .text-r-sm {
    @apply text-xs;
    @apply md:text-sm;
  }

  .btn {
    @apply rounded-lg;
    border-radius: 16px;
    @apply text-r-xl;
    @apply font-semibold;
    @apply px-6 py-2;
  }

  .btn-primary {
    @apply btn bg-black text-white hover:bg-neutral-600;
  }

  .btn-secondary {
    @apply btn bg-green-500 text-white hover:bg-green-300;
  }

  .pg-2xl {
    @apply text-neutral-600;
    @apply text-r-2xl;
    @apply leading-1.1;
  }

  .pg-3xl {
    @apply text-neutral-600;
    @apply text-r-3xl;
    @apply leading-1.2;
  }

  .chairs-bg {
    background-image: url("/images/three-white-chairs.png"),
      linear-gradient(180deg, #ececec 0%, #f5f5f5 100%);
    background-repeat: no-repeat;
    background-position: right;
  }

  .womens-discussing-gradient {
    background-image: linear-gradient(
        90deg,
        #282828 0%,
        rgba(40, 40, 40, 0) 100%
      ),
      url("/images/bg-womens-discussing.png");
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rich-text dl:not(:last-child),
.rich-text ol:not(:last-child),
.rich-text ul:not(:last-child),
.rich-text > p:not(:last-child),
.rich-text pre:not(:last-child),
.rich-text table:not(:last-child) {
  margin-bottom: 1.5em;
}

.layout-container {
  position: relative;
}

@media screen and (min-width: 1060px) {
  .layout-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 952px;
    width: 952px;
  }
}
@media screen and (min-width: 1252px) {
  .layout-container {
    max-width: 1144px;
    width: 1144px;
  }
}
@media screen and (min-width: 1444px) {
  .layout-container {
    max-width: 1336px;
    width: 1336px;
  }
}
